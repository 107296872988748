import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  h1, h2, h3, h4 {
    font-family: "Sora", Helvetica, sans-serif;
  }
  
  .btn-primary {
    border-radius: 8px;
    color: white;
  }

  .btn-outline-primary {
    color: ${orange.base};
    border-radius: 8px;
    :hover {
      background: inherit;
    }
    :active {
      background: transparent !important;
      color: ${orange.extra} !important
    }
  }

  .container-footer {
    border-top: none ;
  } 
`
